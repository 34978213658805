.asset__actions--scroll {
  overflow: unset !important;
  overflow-y: scroll !important;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #1B5579;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3c87bd;
    border-radius: 0px;
  }
}

.asset__wizard {
  .w_step__title {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .w_step__text {
    font-weight: 400;
    font-size: 16px;
    color: #bdbdbd;
    line-height: 24px;
  }
}