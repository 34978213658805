.login {
  .formik-form-group {
    .sign__input {
      background-color: #1B5579 !important;
    }
    .input-group-append {
      .input-group-text {
        background-color: inherit !important;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid #222227;
  -webkit-text-fill-color: white;
  box-shadow: 0 0 0px 1000px #222227 inset;
  -webkit-box-shadow: 0 0 0px #222227 inset;
  transition: background-color 5000s ease-in-out 0s;
}
