.card__cover {
  .item__cover__wrapper {
    position: absolute;
    display: block;
    overflow: hidden;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;

    .item__cover__image {
      position: absolute;
      inset: 0px;
      box-sizing: border-box;
      padding: 0px;
      border: none;
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

.card__cover__new {
  border-radius: 0px !important;

  .item__cover__wrapper {
    position: absolute;
    display: block;
    overflow: hidden;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;

    .item__cover__image {
      position: absolute;
      inset: 0px;
      box-sizing: border-box;
      padding: 0px;
      border: none;
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}
