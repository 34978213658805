@import "./custom-bootstrap-variables.scss";
@import "~bootstrap/scss/bootstrap.scss";

.navbar {
  padding: unset;
  .nav-link {
    color: #fff !important;
  }
}

.nav-tabs {
  .nav-link {
    &.active {
      background-color: unset;
      border-color: unset;
      color: #fff;
      border: unset;
    }
    background-color: unset;
    border-color: unset;
    border: unset;
    &:focus {
      border-color: unset;
    }
    &:hover {
      border-color: unset;
    }
  }
}

.btn {
  font-size: 0.9em;
  padding: 0.8rem 0;
  border-radius: 12px;
  &:focus {
    box-shadow: unset;
  }
  &.btn-primary {
    transition: 0.5s ease;
    transition-property: color, background-color, border-color;
    &:hover,
    &:focus {
      color: var(--primary);
      background-color: var(--secondary);
      border-color: var(--secondary);
    }
    &:active {
      color: var(--primary);
      background-color: var(--secondary) !important;
      border-color: var(--secondary) !important;
      box-shadow: unset !important;
    }
  }
  &.btn-secondary {
    transition: 0.5s ease;
    transition-property: color, background-color, border-color;
    &:hover,
    &:focus {
      background-color: var(--primary);
      border-color: var(--primary);
    }
    &:active {
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;
      box-shadow: unset !important;
    }
  }
}

.form-control {
  color: #fff;
  &:focus {
    background-color: #1B5579;
    box-shadow: unset;
    color: #fff;
  }
  &::placeholder {
    color: #bdbdbd;
  }
}

.cursor-pointer {
  cursor: pointer;
}
