.formik-form-group {
  width: 100%;
  .form-control {
    &.error {
      border-color: var(--error);
    }
  }

  .password_field {
    // border-top-right-radius: 12px !important;
    // border-bottom-right-radius: 12px !important;
  }
  .input-group-append {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    .input-group-text {
      background-color: #1B5579;
      border: unset;
      color: #fff;
    }
  }

  .invalid-feedback {
    color: var(--error);
    margin-left: 20px;
  }
}

.formik__input__icon {
  filter: invert(100%) sepia(3%) saturate(563%) hue-rotate(154deg)
    brightness(77%) contrast(90%);
}

.sign__select {
  // background: url("../img/arrow2.svg") no-repeat center right 20px #1B5579;
  background-size: 18px auto;
  border: 1px solid transparent;
  border-radius: 0px;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
}