.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.base__modal {
  .modal-dialog {
    transform: scale(0.5);
    transition: transform 0.3s ease-in-out;
  }

  &.show {
    .modal-dialog {
      transform: scale(1);
    }
  }

  .close {
    color: #bdbdbd !important;
  }
}
