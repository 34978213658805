.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
.image__ {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.certifiedCard {
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
  background: url("../../assets/verified.svg") no-repeat center #2f80ed;
  content: "";
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  z-index: 1;
}
.certification_author__code {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 26px;
  height: 32px;

  border-radius: 10px;
  position: relative;
}
.certification_author__code input {
  width: 100%;
  border-radius: 0px;
  border: none;
  background: #16151a;
  mix-blend-mode: screen;
  color: #bdbdbd;
  font-size: 19px;
  padding-right: 60px !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.certification_author__code button {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  width: 40px;
  top: 3px;
  right: 10px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0s;
}
.certification_author__code button svg {
  fill: #4BA4E3;
  width: 25px;
  height: auto;
  opacity: 0.75;
  transition: opacity 0.5s ease;
}
.certification_author__code button svg:hover {
  opacity: 1;
}
.asset__action {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 20px;
}
.asset__action--verified:after {
  content: "";
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  bottom: -2px;
  left: 28px;
  border-radius: 50%;
  background: url("../../assets/verified.svg") no-repeat center #2f80ed;
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}
.asset__action img {
  display: block;
  overflow: hidden;
  width: 44px !important;
  height: 44px;
  border-radius: 10px;
  margin-right: 10px;
}
.certification_asset__actions--scroll {
  height: 400px;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

.certification__share__qr {
  width: 100% !important;
  height: 100% !important;
  max-width: 340px;
  max-height: 340px;
}

.certification__section__title {
  font-size: 31px;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 400px) {
  .certification__section__title {
    font-size: 24px;
  }
}

div::-webkit-scrollbar-track {
  background-color: transparent;
}

div::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

div::-webkit-scrollbar-thumb {
  background-color: #6164ff;
  border-radius: 10px;
}
div.noScrollThumb::-webkit-scrollbar-track {
  display: none;
}
div.noScrollThumb::-webkit-scrollbar {
  display: none;
}
.noScrollThumb::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.noScrollThumb {
  scroll-behavior: smooth;
}

.cursor__pointer {
  cursor: pointer;
}
